@import '../../../assets/style/colors';

.simpleMessages {
    .message {
        margin-bottom: 25px;

        h4 {
            margin-bottom: 0;
            font-size: 16px;
        }

        &.is-danger {
            .subtitle,
            .content {
                color: $danger;
            }
        }

        &.is-success {
            .message-body {
                background: rgba(4,203,4,0.1);

                li {
                    &:before {
                        background: #04cb04;
                    }

                    &:after {
                        mask-image: url(../../../assets/images/icon-checkmark.svg);
                    }
                }
            }
        }

        &.is-danger {
            .message-body {
                li {
                    &:before {
                        background: $danger;
                    }

                    &:after {
                        mask-image: url(../../../assets/images/cross-mark.svg);
                        mask-size: 50% 50% !important;
                        mask-position: center center;
                    }
                }
            }
        }

        .message-body {
            border: none;
            color: $buster;
            padding: .625rem .875rem .875rem;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            .content {
                margin: 0;
                font-size: .875rem;
                color: $buster;

                .subtitle {
                    color: $buster;
                    margin-bottom: .875rem;
                    font-weight: 500;
                    font-size: .875rem;
                }

                ul {
                    margin-left: 0;
                    margin-top: .5em;
                    padding: 0;

                    li {
                        padding-left: 2.25rem;
                        list-style: none;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: 0;
                        }

                        &:after {
                            content: '';
                            mask-repeat: no-repeat;
                            mask-size: 100% 100%;
                            position: absolute;
                            background: $white;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            top: 0;
                        }
                    }
                }
            }

            button {
                &.delete {
                    background: red;
                    mask-image: url(../../../assets/images/cross-mark.svg);
                    mask-repeat: no-repeat;
                    mask-size: 100% 100%;
                    color: $buster;
                    width: 1rem;
                    height: 1rem;
                    min-width: 1rem;
                    min-height: 1rem;
                    max-width: 1rem;
                    max-height: 1rem;
                    margin-left: 1rem;
                    margin-top: .3rem;

                    &:before,
                    &:after {
                        content: '';
                        display: none;
                    }
                }
            }
        }
    }
}
