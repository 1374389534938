@import '../../assets/style/colors';

.login {
    .form--inputs {
        .helpTotp {
            margin-bottom: 10px !important;
            font-size: 14px;
            color: $buster;

            a {
                color: $primary;
            }
        }

        .emailInputBox {
            position: relative;

            input {
                &:disabled {
                    padding-right: 70px;
                }
            }

            > a {
                z-index: 10;
                font-size: 14px;
                position: absolute;
                right: 10px;
                top: 34px;
            }
        }

        .form_group_recaptcha > div > div {
            display: flex;
            place-content: center;
            
            @media (max-width: 350px) {
                transform: scale(0.85);
            }

        }
    }
}