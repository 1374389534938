@import '../../../assets/style/colors';

.usersPage {
    .buttonBox {
        padding-right: 0;
        button {
            font-size: 14px;
            background-color: $primary;
            color: $white;
            float: right;

            &:hover {
                background-color: $primary;
            }

            svg {
                margin-right: 7px;
            }
        }
    }

    .pageBody {
        .orgTable {
            a {
                margin-left: 15px;
            }

            tr td {
                vertical-align: middle;
            }

            .loadMoreRow {
                text-align: center;
            }
        }
    }
}

.addUserModal {
    .label {
        margin-bottom: 5px;
        font-weight: 500;
    }

    input {
        margin-bottom: 10px;
    }

    .radio {
        display: block;
        margin-left: 10px;
        margin-top: 5px;

        input {
            margin-bottom: 0;
            margin-right: 8px;
        }
    }
}

.text-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}
