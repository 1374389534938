@import '../../../assets/style/colors';

.securityPage {
    // Comment

    .pageHeader {
        // Comment
    }
}


.pageBody {

    .addButton {
        background-color: $primary;
        // background-color: $danger;
        border-color: transparent;
        color: white !important;
        font-size: 12px;
        margin-right: 5px;


        &:disabled {
            background-color: $primary;
            opacity: .5;

            &:hover,
            &:focus {
                opacity: .6;
            }
        }
    }

    .removeButton {
        background-color: $danger;
        border-color: transparent;
        color: white !important;
        font-size: 12px;

        &:disabled {
            background-color: $danger;
            opacity: .5;

            &:hover,
            &:focus {
                opacity: .6;
            }
        }
    }
}
