@import '../../../assets/style/colors';

$cardWidth: 550px;

.cardLayout {
    .navbar {
        .navbar-brand {
            @media (min-width: 600px) {
                width: $cardWidth;
                margin: 0 auto;
            }

            .navbar-item {
                padding: 0;
            }
        }
    }


    .cardContainer {
        text-align: start;
        max-width: $cardWidth;
        margin: 0 auto;
        box-shadow: none;
        border: 1px solid $white-ter;

        h1 {
            color: $primary;

            &.danger {
                color: $danger;
            }
        }

        .card-content {
            padding: 40px;
        }

        .content {
            margin-top: 40px;

            ul {
                margin-left: 0;
                margin-top: 0;
            }

            b {
                font-weight: 500;
            }

            button {
                transition: all .3s ease-in-out;
                text-transform: uppercase;
                opacity: 1;
                font-family: inherit;

                &.is-primary {
                    color: $white;
                    background-color: $primary;

                    &:disabled {
                        color: $black-bis;
                        background-color: $white-bis;
                    }

                    &:not(:disabled):hover {
                        background-color: hsl(219, 96%, 47%);
                    }
                }
            }
        }
    }
}
