@import '../../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.rsaKeyContainer {

    .subtitle {
        font-weight: 500;
        margin: 0px;
        font-size: 15px
    }

    .contentContainer {

        padding: 20px;
        margin-top: 10px;
        margin-bottom: 30px;
        box-shadow: none;
        border: $solitude 1px solid;
        border-radius: 6px;



        .infoMessageContainer {

            background-color: $solitude;
            margin-bottom: 15px;
            padding: 10px;

            .infoIconCol {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding-right: 0 !important;

                &>:first-child {
                    font-size: 1.25em !important;
                    margin-right: 5px;
                }
            }

            p {
                font-size: 14px;

                &:first-of-type {
                    font-weight: bold;
                }
            }

        }

        .rsaLabel {
            font-size: 14px !important;
        }

        .inputCol,
        .btnCol {
            display: flex;
            align-items: center;

            &>:first-child {
                max-height: 35px;
            }

        }

        .inputCol,
        .btnCol,
        .labelCol {
            padding-right: 0px;

            @media screen and (max-device-width: $smWidth) {
                padding-right: 12px;
            }
        }


        .publicKeyContainer {
            font-size: 14px;

            @media screen and (max-device-width: $smWidth) {
                .column {
                    &:last-of-type {
                        padding-top: 0px;
                    }
                }
            }

            .addButton,
            .removeButton {
                margin-top: 10px;
            }

        }
    }
}
