@import '../../../assets/style/colors';

.usersTable {
    .searchRow {
        padding-right: 0;

        .searchControl {
            position: relative;
            display: flex;

            .searchType {
                margin-right: 10px;
            }

            .clearSearchBtn {
                cursor: pointer;
                position: absolute;
                right: 15px;
                top: 9px;
                color: $danger;
            }
        }
    }
}