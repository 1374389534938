@import '../../assets/style/colors.scss';

.sso--layout {
    &:before {
        content: '';
        position: absolute;
        height: 100% !important;
        min-height: 100vh;
        z-index: 0;
        right: 0;
        top: 0;
    }

    &.dividedBackground {
        &:before {
            @media (min-width: 769px) {
                width: 50%;
                background-color: $grey-lighter;
            }
        }
    }

    &.fullBackground {
        &:before {
            width: 100%;
            background-color: $grey-lighter;
        }
    }
}