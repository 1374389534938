@import '../../../assets/style/colors';

$xsWidth: 480px;

.pageHeader {

    .headerCol {
        display: flex;
        align-items: center;

        &:first-of-type {
            justify-content: flex-start;

            &:first-child {
                align-items: flex-end;
            }
        }

        &:last-of-type {
            justify-content: flex-end;

            &:first-child {
                align-items: center;
            }
        }

        svg {
            font-size: 13px;
            margin-right: 7px;
            margin-bottom: 1px;
        }

        a {
            font-size: 16px;
            color: $primary;
        }

        h2 {
            font-size: 15px;
            font-weight: 500;
        }

    }

}

.xScrollTable {

    box-shadow: none;
    border: $solitude 1px solid;
    border-radius: 6px;
    padding: 20px;
    table-layout: fixed;
    width: 100%;

    .orgTableName {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer !important;

        &>div {
            display: flex;
            align-items: center;
            margin-left: 15px;
            color: $primary;
        }
    }

    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    table tbody {
        display: table;
        width: 100%;
        height: 100%;

    }

    td {
        height: 100%;

        &:not(:first-child) {
            text-align: center;
        }

        a {
            margin-right: 15px;
        }
    }

}
