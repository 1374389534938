@import '../../assets/style/colors.scss';

$xsWidth: 480px;

.register {
    flex-grow: 1;

    h1,
    h4 {
        color: $primary;
    }

    label.required:after {
        content: '*';
    }

    &--form {
        border: 1px solid $white-ter;
        background-color: $white;
        font-family: inherit;

        @media (min-width: 1216px) {
            margin: 0 4rem;
        }

        &-columns {
            display: flex;
        }

        &-column {
            width: 50%;

            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                padding-left: 10px;
            }
        }

        &-inputs {
            padding: 2.5rem;

            @media (max-width: 574px) {
                padding: 1rem;
            }
        }

        &-checkboxes {
            p {
                margin-bottom: 1.25rem;
                font-size: .90rem;
            }
            input {
                align-self: flex-start;
                margin-top: 5px;
            }
        }

        &-description {
            margin-bottom: 1.25rem;
            margin-top: 3rem;
            font-size: 13px;
            color: $englishManor;
        }

        &-captcha {
            > div > div > div {
                margin: 15px auto;
                @media (max-width: 400px) {
                    transform: scale(0.9);
                    transform-origin: 0 0
                }

                @media (max-width: 340px) {
                    transform: scale(0.8);
                }

                @media (max-width: 310px) {
                    transform: scale(0.75);
                }
            }
        }
    }
}
