@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.addNewEntry {
    td {
        padding-right: 0;
    }

    label {
        font-size: 14px;
        font-weight: normal;
    }

    .addNewEntryWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $xsWidth) {
            flex-direction: column;
        }

        .addNewEntryInputs {
            width: 100%;
            margin-right: 10px;

            @media (max-width: $xsWidth) {
                margin-bottom: 10px;
            }

            .control {
                width: 100%;

                &.is-half {
                    width: 50%;
                    display: inline-block;

                    &:not(:last-of-type) {
                        padding-right: 10px;
                    }
                }
            }

            input,
            select,
            .select {
                width: 100%;
                min-width: 100%;
                margin-right: 10px;
                font-size: 16px;
            }
        }

        .addNewEntryButtons {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .button,
            .button:hover {
                @media (max-width: $xsWidth) {
                    width: 25%;
                    &:first-of-type {
                        width: 75%;
                    }
                }

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                &.is-info {
                    font-size: 16px;
                    background-color: $primary;
                    color: $white;

                    svg {
                        margin-right: 7px;
                    }

                    &:disabled {
                        background-color: $primary;
                        opacity: .5;
                        transition: all .2s ease-in-out;

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }
        }
    }

    .highlightDelete {
        color: $danger;
        font-weight: 500;
    }

    .invalidInput {
        box-shadow: none !important;
        border-color: $danger;
    }

    .validInput {
        border-color: $demeterGreen !important;
    }
}
