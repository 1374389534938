@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.simpleModal {
    .modal-card {
        min-width: 570px;

        @media (max-width: $smWidth) {
            min-width: 480px;
        }

        @media (max-width: $xsWidth) {
            min-width: unset;
        }

        .modal-card-body {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .highlightDelete {
        color: $danger;
        font-weight: 500;
    }

    .invalidInput {
        box-shadow: none !important;
        border-color: $danger;
    }
}