@import '../../../assets/style/colors';

$xsWidth: 480px;

.licensesTable {


    .card {
        margin-top: 10px;
        margin-bottom: 30px;
        box-shadow: none;
        border: $solitude 1px solid;
        border-radius: 6px;

        .card-header {
            padding: 20px;
            display: block;
            box-shadow: none;
            border-bottom: $solitude 1px solid;

            .subtitle {
                font-weight: 500;
                margin: 0;
            }
        }

        .card-content {
            padding: 20px;

            table {
                color: $buster;
                font-size: 14px;

                .show-xs {
                    display: none;
                }

                @media (max-width: $xsWidth) {
                    .hidden-xs {
                        display: none;
                    }

                    .show-xs {
                        display: block;
                    }
                }

                tbody {
                    tr {
                        background-color: transparent !important;

                        td {
                            border: none;
                            padding-left: 0;
                            padding-bottom: 20px;
                            padding-top: 0;

                            &:first-child {
                                width: 1%;
                                white-space: nowrap;
                                min-width: 250px;
                            }
                        }

                        &:last-child {
                            td {
                                padding-bottom: 0;
                            }
                        }

                        &:nth-child(odd) {
                            td {
                                @media (max-width: $xsWidth) {
                                    padding-bottom: 3px;
                                }
                            }
                        }

                        &:nth-last-child(2) {
                            td {
                                @media (min-width: $xsWidth) {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card-footer {
            padding: 20px;

            @media (max-width: $xsWidth) {
                display: block;
            }

            .card-footer-item {
                border: none;
                display: block;
                padding: 0;
                text-align: right;

                a {
                    font-size: 16px;
                    color: $primary;
                }

                svg {
                    font-size: 13px;
                    margin-left: 5px;
                    margin-bottom: 1px;

                }

                @media (max-width: $xsWidth) {
                    text-align: left;
                }
            }
        }
    }

    .orgName {
        @media (min-width: $xsWidth) {
            padding-bottom: 1.5rem;
        }

        .ctrlCol {
            text-align: right;

            .editNameBtn {
                color: $primary;
                margin-right: 10px;
            }
        }
    }

    p {
        &.bold {
            font-weight: 500;
        }
    }

    .subtitle {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 500;
    }

    .orgHalfHeadline {
        @media (min-width: $xsWidth) {
            display: inline-flex;
        }

        p {
            font-size: .95em;

            &.selectAll {
                user-select: all;
            }

            &.mr-2 {
                margin-right: 1em;
            }
        }
    }
}
