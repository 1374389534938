@import '../../../assets/style/colors';

.actionLayout {
    .action-wrapper {
        font-family: inherit;

        color: $buster;

        .messageTitle {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .messageContent {
            font-size: 14px;
            font-weight: 500;
        }

        .questionContainer {
            margin-top: 2.5rem;
            padding-top: 1.875rem;
            border-top: 1px solid $solitude;
            font-size: .875rem;
            color: $buster;

            p {
                margin-bottom: 10px;
            }
        }
    }
}
