@import './colors.scss';

$simplonFamily: 'SimplonNorm';

html {
    min-height: 100%;
    position: relative;
}

body {
    height: 100%;
}

html,
body {
    font-size: 16px;
    font-family: $simplonFamily;
}

.section {
    padding: 1.5rem;

    h1 {
        font-size: 2.4rem !important;
        font-weight: 400;
        line-height: 2.9rem;
    }
    
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
}

.modal-card {
    width: 100%;
    margin: 0;
    padding: 0 20px;

    .modal-card-head {
        padding: 20px 15px 15px;
        border-bottom: none;
        background-color: $white;
        align-content: center;

        .delete {
            background-color: $white;
            width: 30px;
            height: 30px;
            min-height: 30px;
            min-width: 30px;
            max-width: 30px;
            max-height: 30px;
            margin-left: 20px;

            &:before {
                background-color: $grey-light;
                height: 3px;
            }

            &:after {
                background-color: $grey-light;
                width: 3px;
            }
        }

        .modal-card-title{
            margin: 0;
        }
    }

    .modal-card-body {
        .content {
            color: $buster;
            font-weight: 500;
        }
    }

    .modal-card-foot {
        border-top: none;
        background-color: $white;
        text-align: center;
        justify-content: center;

        .button {
            min-width: 100px !important;
            margin: 0 20px;
            color: $primary;
            font-weight: 500;

            &.is-info {
                background-color: $primary !important;
                color: $white;
            }

            &.is-danger {
                background-color: $danger !important;
                color: $white;
            }
        }
    }
}