@import '../../../assets/style/colors';

$lgWidth: 1024px;

.mfaPage {
    h2 {
        display: inline-block;
        font-size: 14px !important;
        font-weight: 500;

        &.required {
            color: $danger;
        }
    }

    a {
        color: $primary;
    }

    .mfaOutlineBox {
        padding: 0 20px;
        border: 1px solid $solitude;
        border-radius: 6px;

        h3 {
            margin: 20px 0 10px 0;
        }

        p {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    .columns {
        margin-top: 20px;

        .column {
            text-align: center;
        }
    }

    .field {
        margin-bottom: 0;

        input {
            max-width: 200px;
            border-radius: 6px !important;
            padding: 25px 20px 23px 20px !important;
            font-size: 14px !important;
            border-color: $cheerfulWhisper;
        }
    }


    .button {
        margin-top: 20px;
        color: $primary;
        font-weight: 500;
        padding: 20px 36px;
        margin-right: 20px;

        &.is-info {
            background-color: $primary !important;
            color: $white;
        }

        &.is-danger {
            background-color: $danger !important;
            color: $white;
        }

        &.is-dark {
            background-color: $grey-light !important;
            color: $white;
        }
    }

    .qrCodeContainer {
        text-align: center;

        .button {
            margin-left: 20px;
        }

        .qrSetup {
            margin: 0;

            .column {
                border: 1px solid $solitude;
                border-radius: 6px;
                padding: 35px;
                margin-top: 20px;
                position: relative;

                @media (min-width: $lgWidth) {
                    canvas {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:first-child {
                        margin-right: 10px;
                    }

                    &:last-child {
                        margin-left: 10px;
                    }
                }

                .getApp {
                    font-size: 14px;
                    margin-bottom: 25px;
                }

                a {
                    display: block;
                }

                img {
                    height: 80px;
                    width: 190px;

                    &.appStore {
                        padding: 0 12px 12px 12px;
                        margin-top: -12px;
                    }
                }
            }
        }
    }

    .totpInput {
        display: block;
        max-width: 250px;
        margin: auto;

        input {
            margin-bottom: 0;
            border-radius: 6px;
            border-color: $cheerfulWhisper;

            &:focus {
                border-color: $primary;
            }
        }
    }

    .message {
        margin-top: 10px;

        .content {
            ul {
                margin-top: 0;
            }
        }
    }
}