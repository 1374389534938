@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.profileInfo {
    @media (max-width: $smWidth) {
        margin-bottom: 40px;
    }

    .columns {
        margin-bottom: 0;
        margin-top: 5px;

        .column {
            padding-bottom: 0;
            padding-top: 0;

            @media (min-width: $smWidth) {
                &:first-child {
                    padding-right: 0.5rem;
                }

                &:last-child {
                    padding-left: 0.5rem;
                }
            }
        }
    }

    .subtitle {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 500 !important;
    }

    input {
        border-radius: 6px !important;
        height: 50px !important;
        padding: 0 20px !important;
        font-size: 14px !important;
        border-color: $cheerfulWhisper;

        &:read-only {
            background-color: $gramsHair !important;

            &:focus, &:hover {
                border-color: $cheerfulWhisper;
            }
        }

        &.change {
            border-color: $demeterGreen !important;

            &.invalid {
                border-color: $danger !important;
            }
        }
    }

    .subscriptions {
        margin-bottom: 40px;

        .subtitle {
            margin-bottom: 12px;
        }

        p {
            font-size: 14px;
        }
    }

    .message {
        .message-body {
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    .buttons {
        margin-top: 20px;

        button {
            &:first-child {
                margin-right: 30px;
            }

            &.positive {
                background-color: $primary;
            }

            &.negative {
                color: $primary;
                border-color: transparent;
            }

            @media (max-width: $xsWidth) {
                display: flex;
                width: 100%;
                margin-right: 0 !important;

                &.negative {
                    margin-top: 15px;
                    border-color: $grey-light;
                }
            }
        }
    }
}