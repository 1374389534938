@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.floatRight {
    float: right;
}

.organizationsPage {

    .pageHeader {

        .organization-dropdown {

            .button,
            .button:focus {
                border: none !important;
                box-shadow: none !important;

                padding: 5px;
                padding-left: 0px;

                .organization-menu {
                    position: relative;
                    display: flex;

                    .organization-name {
                        margin-left: 0;
                        margin-bottom: 0;
                    }

                    .icon {
                        position: absolute;
                        transition: transform .25s linear;
                        right: -20px;
                    }
                }
            }

            .dropdown-menu {
                @media (min-width: $xsWidth) {
                    left: 0;
                    right: auto;
                }

                display: block;
                opacity: 0;
                transition: all .3s ease-in-out;
                pointer-events: none;
                top: calc(100% + (-4px));
                transform: translateY(-5px);
                min-width: 170px;

                .dropdown-content {
                    box-shadow: none;
                    border: $solitude 1px solid;
                    font-weight: 400 !important;

                    .dropdown-item {
                        cursor: pointer;
                        color: $buster;

                        &:hover {
                            background-color: $white-bis;
                        }
                    }
                }
            }

            &.is-active {

                .icon {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                .dropdown-menu {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: auto;
                }
            }
        }


        .ctrlCol {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .goToParentBtn {
                display: flex;
                align-items: center;
                color: $primary;

                &>svg {
                    margin-right: 7px;
                }
            }
        }
    }
}
