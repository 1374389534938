@import '../../../assets/style/colors';

.secretCodeContainer {
    border: 1px solid $solitude;
    border-radius: 6px;
    padding: 0;
    font-size: 14px;
    background-color: $gramsHair;
    margin: 0;

    .column {
        text-align: left;
        padding: 10px 25px 6px 25px;

        p {
            overflow-wrap: anywhere;
            text-align: left;
            margin: 0 !important;
        }

        a {
            font-weight: 500;
        }
    }
}