.ErrorBoundary {
    border-radius: 5px;
    box-sizing: border-box;
    line-height: 25px;
    max-width: 1200px;
    margin: 100px auto auto;
    text-align: center;
    color: #2672FB;

    img {
        width: 100px;
        filter: invert(0.4) sepia(1) saturate(5) hue-rotate(190deg);
    }

    h1 {
        font-size: 26px;
        font-weight: 300;
    }
}
