@import '../../../assets/style/colors';

$xsWidth: 480px;

.licenseCard {
    margin-bottom: 40px;
    margin-top: -4px;

    .productTitle {
        margin-bottom: 10px;
        position: relative;

        h2 {
            display: inline-block;
            margin-right: 7px;
            margin-bottom: 0;
        }

        .iconWrapper {
            position: absolute;

            .icon {
                font-size: 14px;
                color: $primary;
            }
        }
    }

    .subtitle {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    .card {
        box-shadow: none;
        border: $solitude 1px solid;
        border-radius: 6px;

        .card-header {
            padding: 20px;
            display: block;
            box-shadow: none;
            border-bottom: $solitude 1px solid;

            .title {
                font-size: 26px;
                margin: 0;
            }

            .subtitle {
                font-weight: 400;
            }
        }

        .card-content {
            padding: 20px;

            .content-wrapper {

                &> :first-child,
                .field {
                    margin-bottom: 10px;
                }

                .content-header {
                    padding-top: 10px;
                    border-top: $solitude 1px solid;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &>p {
                        font-weight: 500;
                    }

                    .addButton {
                        background-color: $primary;
                        border-color: transparent;
                        color: white !important;
                        font-size: 13px;
                        margin-right: 5px;


                        &:disabled {
                            background-color: $primary;
                            opacity: .5;

                            &:hover,
                            &:focus {
                                opacity: .6;
                            }
                        }
                    }

                }

                .content-body {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .content-page-btns {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    &>a {
                        display: flex;
                        align-items: center;
                    }

                    .fa-arrow-left {
                        margin-right: 5px;
                    }

                    .fa-arrow-right {
                        margin-left: 5px;
                    }
                }
            }

            table {
                color: $buster;
                font-size: 14px;

                .show-xs {
                    display: none;
                }

                @media (max-width: $xsWidth) {
                    .hidden-xs {
                        display: none;
                    }

                    .show-xs {
                        display: block;
                    }
                }

                tbody {
                    tr {
                        background-color: transparent !important;

                        td {
                            border: none;
                            padding-left: 0;
                            padding-bottom: 20px;
                            padding-top: 0;

                            &:first-child {
                                width: 1%;
                                white-space: nowrap;
                                min-width: 200px;
                            }
                        }

                        &:last-child {
                            td {
                                padding-bottom: 0;
                            }
                        }

                        &:nth-child(odd) {
                            td {
                                @media (max-width: $xsWidth) {
                                    padding-bottom: 3px;
                                }
                            }
                        }

                        &:nth-last-child(2) {
                            td {
                                @media (min-width: $xsWidth) {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card-footer {
            padding: 20px;

            @media (max-width: $xsWidth) {
                display: block;
            }

            .card-footer-item {
                border: none;
                display: block;
                padding: 0;

                a {
                    font-size: 16px;
                }

                &:first-child {
                    a {
                        color: $englishManor;
                    }
                }

                &:last-child {
                    text-align: right;

                    a {
                        color: $primary;
                    }

                    @media (max-width: 480px) {
                        margin-top: 20px;
                        text-align: left;
                    }
                }
            }
        }
    }

}
