@import '../../assets/style/colors.scss';
$marginTop: 12px;

$smWidth: 768px;

.unknown--page {
    .unknown--content {
        text-align: center;

        @media (min-width: $smWidth) {
            padding-top: 60px;
            padding-bottom: 40px;
        }

        @media (max-width: $smWidth) {
            padding-top: 20px;
            padding-bottom: 60px;
        }
    
        h1 {
            font-size: 26px !important;
            line-height: 30px;
        }
    
        p {
            margin-top: $marginTop;
        }
    
        img {
            width: 100px;
            margin-bottom: $marginTop;
        }

        .button {
            &.is-primary {
                margin-top: $marginTop * 2;
                background-color: $primary;
            }
        }
    }
    

    .navbar-menu {
        display: none !important;
    }
}