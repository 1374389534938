@import '../../assets/style/colors';

$mobile: 768px;
$tablet: 769px;
$widescreen: 1216px;

.homeLayout {
    flex-grow: 1;
    height: 100vh;

    .switchPageText {
        font-size: .875rem;
        color: $buster !important;
        margin: 2.5rem 0;
    }

    @media (max-width: $mobile) {

        .container,
        &.section,
        .leftPanelWrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .rightPanelWrapper {
            padding-left: 2rem !important;
            padding-right: 1.3rem !important;
        }

        .leftPanel {
            padding: 1.5rem !important;
            padding-top: 2.5rem !important;
            padding-bottom: 2.5rem !important;

            .productTexts {
                text-align: right;
            }
        }

        .columns {
            margin-right: 0 !important;
        }
    }

    @media (max-width: 375px) {
        .leftPanel {
            align-items: flex-start !important;

            .productTexts {
                max-width: 108px;
            }
        }
    }

    @media (min-width: $tablet) {

        .container,
        .columns {
            height: 100%;
        }

        .rightPanelWrapper {
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 5.5rem;
            padding-bottom: 5.5rem;
            overflow-y: visible;

            .form--wrapper,
            .action-wrapper {
                width: 100%;
            }
        }
    }

    .formHeader {
        p {
            color: $buster !important;
            font-size: .875rem;

            &.h3 {
                font-size: 1.375rem;
                line-height: 30px;
                font-weight: 500;
            }

            &.appName {
                margin-top: 10px;
            }

            strong {
                font-weight: 500 !important;
                color: $buster !important;
            }
        }
    }

    .leftPanelWrapper {
        position: relative;

        .leftPanel {
            background: url(../../assets/images/left-panel-form-layout.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right;
            width: 30%;
            margin: 0;
            padding: 1.5rem 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .logoWrapper {
                width: 150px;
                cursor: pointer;

                img {
                    filter: brightness(0) invert(1);

                    &.originLogo {
                        height: 30px;
                    }

                    &.invertBrightLogo {
                        filter: brightness(1) invert(0);
                    }
                }

                span {
                    color: $white;
                    font-size: 14px;
                    line-height: 16px;
                    display: block;
                }

                @media (max-width: 375px) {
                    height: 44px;
                    width: 120px;

                    img {
                        &.originLogo {
                            height: 20px;
                        }
                    }

                    span {
                        font-size: 12px;
                        line-height: 13px;
                    }
                }
            }

            @media (min-width: $tablet) {
                position: fixed;
                left: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                padding: 2.5rem !important;
            }

            @media (max-width: $mobile) {
                width: 100%;
            }

            p {
                color: $white;
                font-size: .9rem;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }

    .form--wrapper,
    .action-wrapper {
        max-width: unset;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: $widescreen) {
            max-width: 50%;
        }
    }

    .form--button {
        transition: all .3s ease-in-out;
        border-radius: 0;
        font-size: 14px;
        text-transform: none;
        line-height: 20px;
        font-weight: 500;
        margin-top: 3.75rem;
        padding: 1.25rem 0 !important;

        &.is-primary {
            color: $white;
            background-color: $primary;
            opacity: 1;
            font-family: inherit;

            &:disabled {
                color: $englishManor;
                background-color: $white-bis;
            }

            &:not(:disabled):hover {
                background-color: $primary;
            }
        }
    }

    h1 {
        color: $primary;
    }
}
