// colors https://bulma.io/documentation/overview/colors/

$white: #ffffff;

$primary: #2672FB;
$buster: #3D4A68;
$englishManor: #707EA4;
$enduring: #586687;

$black-bis: #020A11;
$white-ter: #DADADA;
$grey-light: #9C9C9C;
$grey-lighter: #fafafa;
$white-bis: #F1F1F1;
$solitude: #E9ECF2;
$gramsHair: #F4F5F8;
$cheerfulWhisper: #D3D9E6;
$moonWhite: #e8f0fe;
$brainstemGrey: #b5b5b5;
$snowflake: #f0f0f0;

$danger: #FB211E;
$demeterGreen: #04cb04;
$turmericRoot: #FDB00D;
