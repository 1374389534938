@mixin font-face($font-family, $path, $font-weight: 400, $font-style: normal, $font-display: auto, $font-types: svg ttf woff woff2) {
	$src: null;

	$font-types-mods: (
		eot: "?",
		svg: "#" + str_replace($font-family, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $type in $font-types {
		$font-type: if(map-has-key($font-types-mods, $type), $type + map-get($font-types-mods, $type), $type);
		$format: if(map-has-key($formats, $type), map-get($formats, $type), $type);
		$src: append($src, url(quote("#{$path}.#{$font-type}")) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($font-family);
		font-style: $font-style;
		font-weight: $font-weight;
		font-display: $font-display;
		src: $src;
	}
}

@include font-face('SimplonNorm', '../font/SimplonNorm-Bold', 700, normal);
@include font-face('SimplonNorm', '../font/SimplonNorm-Medium', 500, normal);
@include font-face('SimplonNorm', '../font/SimplonNorm-Regular', 400, normal);
@include font-face('SimplonNorm', '../font/SimplonNorm-Light', 300, normal);
