@import '../../assets/style/colors.scss';

.field {
    &.input--custom {
        position: relative;
        margin-bottom: 1.5rem;

        &-error {
            input {
                border: 1px solid $danger;
            }

            label {
                color: $danger !important;
            }
        }

        .input--custom-reset-password {
            font-size: 14px;
            color: $primary;
            margin-top: 10px;
            display: block;
        }

        input {
            font-family: inherit;
            border-color: $solitude;

            &:focus,
            &:active {
                box-shadow: none;
                border: 1px solid $cheerfulWhisper;
                background-color: transparent;
            }
        }

        span {
            line-height: 26px;
        }

        label.input--label {
            font-size: 13px;
            line-height: 17px;
            font-weight: 500;
            color: $buster;
        }

        label.checkbox {
            display: flex;
            font-size: 14px;
            flex-direction: row;
            color: $buster;
            align-items: center;

            input[type='checkbox'] {
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
                margin-bottom: 2px;
                border: 1px solid $cheerfulWhisper;
                margin-right: 10px;
                vertical-align: middle;
                appearance: none;
                transition: all .3s ease-in-out;

                &:focus,
                &:focus-within {
                    box-shadow: none;
                    border: 1px solid $primary;
                    outline: none;
                }

                &:checked {
                    background-color: $primary !important;
                    border: 1px solid $primary;
                    transition: all .3s ease-in-out;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        content: url(../../assets/images/icon-checkmark.svg);
                        position: absolute;
                        color: $white;
                        font-size: 24px;
                        line-height: 0;
                    }
                }
            }
        }

        input:not([type='checkbox']) {
            box-shadow: none;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            color: $buster;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 0;
            background: none;
            z-index: 2;
            position: relative;
            transition: all .3s ease-in-out;

            &:disabled {
                background-color: $grey-lighter;
                border-color: $grey-lighter;
            }

            &:not(:focus) {
                ~ .errors--wrapper {
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                    height: 0;
                    transition: all .2s ease-in-out;
                    color: $white;
                    z-index: -1;
                }

                ~ .passwordMeter {
                    display: none !important;
                }
            }
        }

        .errors--wrapper {
            transition: opacity .2s ease-in-out;
            opacity: 0;

            &-active {
                transition: opacity .3s ease-in-out;
                opacity: 1;
                height: auto;
            }

            li {
                display: flex;
                align-items: baseline;
                margin-top: .5rem;
                font-size: 13px;
                color: $danger;
                transition: all .3s ease-in-out;

                &:before {
                    content: '';
                    display: inline-block;
                    min-width: 10px;
                    min-height: 10px;
                    max-width: 10px;
                    max-height: 10px;
                    background-color: $danger;
                    vertical-align: middle;
                    border-radius: 50px;
                    margin-right: 6px;
                }

                &.is-warning {
                    color: $turmericRoot;

                    &:before {
                        background-color: $turmericRoot;
                    }
                }
            }
        }

        .reveal {
            mask-image: url(../../assets/images/icon-unmask.svg);
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            mask-position: center center;
            background-color: $cheerfulWhisper;
            position: absolute;
            width: 21.5px;
            height: 18px;
            min-width: 21.5px;
            min-height: 18px;
            max-width: 21.5px;
            max-height: 18px;
            right: 10px;
            top: 35px;
            cursor: pointer !important;
            z-index: 2;
            transition: all .3s ease-in-out;
            user-select: none;

            &.revealed {
                mask-image: url(../../assets/images/icon-mask.svg);
                background-color: $buster;
            }
        }

        ::-ms-reveal {
            display: none;
        }

        .passwordMeter {
            display: flex;
            flex-direction: row;
            margin-top: 3px;

            > div {
                min-height: 5px;
                flex: 1;
                background-color: $englishManor;
                transition: all .3s ease-in-out;

                &:nth-child(2) {
                    margin: 0 3px;
                }

                &.week {
                    background-color: $danger;
                }

                &.medium {
                    background-color: $turmericRoot;
                }

                &.strong {
                    background-color: $demeterGreen;
                }
            }
        }
    }
}
