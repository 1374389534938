.navbar {
    margin-bottom: 1.75rem;
    z-index: unset;
    background-color: transparent;
 
    .navbar-brand {
        .navbar-item {
            @media (max-width: 1023px) {
                padding: 0;
            }
            &:hover {
                color: #4a4a4a;
            }

            .logo {
                width: 157px;
                height: 100%;
                background-image: url(../../assets/images/logo.svg);
                background-repeat: no-repeat;
                background-position-y: center;
            }
        }
    }
}