@import '../../assets/style/colors';

.totpInput {
    label {
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        color: $buster;
        margin-bottom: 0.5em;
        display: block;
    }

    input {
        font-size: 20px;
        letter-spacing: 5px;
        text-align: center;
        width: 100%;
        padding: 8px 10px;
        margin-bottom: 15px;
        border-color: $cheerfulWhisper;
        border-width: 1px;
        border-style: solid;
        outline: none;
        color: $buster;
        font-family: SimplonNorm, serif;

        &:focus,
        &:active {
            box-shadow: none;
            border: 1px solid $primary;
            background-color: transparent;
        }
    }
}