@import '../../assets/style/colors.scss';

$cardWidth: 800px;

.consent--page {
    .navbar {
        .navbar-brand {
            @media (min-width: 850px) {
                width: $cardWidth;
                margin: 0 auto;
            }

            .navbar-item {
                padding: 0;
            }
        }
    }

    .consent--card {
        max-width: $cardWidth;

        .content {
            button {
                @media (min-width: 769px) {
                    margin-top: 20px;
                }

                &.is-white {
                    color: $grey-light;
                    border: 2px solid $grey-light;
                }

                &:disabled {
                    color: $grey-light;
                    background-color: $white-bis;
                }

                &:not(:disabled):hover {
                    background-color: $white-ter;
                }
            }
        }
    }
}

.control {

    >.checkbox{

        input {
            align-self: flex-start;
            margin-top: 5px;
        }
    }
}
