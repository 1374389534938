@import '../../../assets/style/colors';

$smWidth: 768px;

.profileSelection {
    margin-top: 70px;
    margin-bottom: 60px;

    .subtitle {
        margin-bottom: 20px !important;
    }

    .column {
        @media (min-width: $smWidth) {
            &.is-half {
                padding-right: 50px !important;

                &:first-child {
                    padding-right: 60px !important;
                }

                &:last-child {
                    padding-left: 60px !important;
                }
            }
        }
    }

    .field {
        margin-bottom: 15px;

        .checkbox {
            input {
                margin-top: 4px;
                margin-right: 10px !important;
                padding: 4px !important;
                min-width: 16px !important;
                min-height: 16px !important;
                max-width: 16px !important;
                max-height: 16px !important;
                border-radius: 3px !important;
                align-self: flex-start !important;

                &:disabled {
                    background-color: $grey-light !important;
                    cursor: not-allowed;
                }
            }

            &.change {
                input {
                    box-shadow: 0 0 3px 2px $primary !important;
                }
            }

            span {
                font-size: 14px;
            }
        }
    }

    .privacyDesc {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
