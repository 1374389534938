@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.entryContainer {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;

    &>.cancelBtn>svg {
        color: $danger;
    }
}

.control {
    &.editInput {
        display: flex;
        height: 100%;
        justify-content: center;


        &>input {
            min-width: 60px;
            max-width: 70%;
            text-align: center;
        }
    }
}
