@import '../../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

table {

    tr {

        td {

            .clientId {

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .clientIdText {

                    display: block;
                    max-width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 5px;
                }
            }

            .clientSecret {

                text-align: center;
                text-indent: 4px;
                padding: 0px 5px;
                cursor: pointer !important;

                &.hideSecrets {

                    background-color: $solitude;
                    transition: transform .2s;

                    &:hover {
                        transform: scale(1.1);
                    }

                    &:hover,
                    &:active {
                        background-color: $cheerfulWhisper  !important;
                    }
                }

                &.showSecrets {

                    transition: transform .2s;
                    background-color: $demeterGreen;

                    &:hover {
                        transform: scale(1.1);
                    }

                }

            }
        }
    }
}
