@import '../../../assets/style/colors';

.formLayout {
    .form--wrapper {
        font-family: inherit;

        .form--inputs {
            padding-top: 32px;
        }

        .tabs {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 1.05rem;

            ul {
                border: none;

                li {
                    a {
                        justify-content: start;
                        border-bottom-width: 2px;
                        margin: 0 1px;
                        font-size: 16px;
                        color: $enduring;
                        border-color: $cheerfulWhisper;

                        &:hover {
                            transition: all .3s ease-in-out;
                            color: $primary;
                            border-color: $primary;
                        }
                    }

                    &.is-active {
                        a {
                            font-weight: 500;
                            color: $buster;
                            border-color: $primary;
                        }
                    }
                }
            }
        }
    }

    .simpleMessages {
        .message {
            margin-bottom: 0;
            margin-top: 25px;
        }
    }
}
