@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.freshestLayout {
    &:before {
        background-color: $white !important;
    }

    h1, h2, h3, h4, h5, h6, span, p, button {
        color: $buster;
    }

    .navbar {
        align-items: stretch;
        display: flex;

        .navbar-menu {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            align-items: stretch;
            box-shadow: none;

            .navbar-end {
                justify-content: flex-end;
                margin-left: auto;
                align-items: stretch;
                display: flex;
            }
        }


        @media (max-width: $xsWidth) {
            display: block;
            margin-bottom: 10px;

            .navbar-menu {
                .navbar-end {
                    display: block;
                    width: 100%;

                    .app-dropdown {
                        float: right;
                    }

                    .dropdown-menu {
                        min-width: calc(100vw - 3rem);
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .navbar-end {
        > .button {
            border: none;
            margin-top: 8px;
        }

        .dropdown {
            margin-top: 8px;

            .button {
                border: none;
                font-size: 14px;
                transition: all .3s ease-in-out;
                font-weight: 500;

                &:hover, &:focus {
                    background-color: $primary;
                    box-shadow: none;

                    span {
                        color: $white !important;
                    }
                }

                > .icon {
                    display: none;
                }
            }

            &.user-dropdown {
                .button {
                    padding: 0 35px 0 10px;
                    margin-left: 3px;

                    .user-menu {
                        position: relative;
                        max-width: 200px;
                        display: flex;

                        .user-name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-family: SimplonNorm, serif;
                            padding-top: 2px;
                        }

                        .icon {
                            position: absolute;
                            transition: transform .25s linear;
                            right: -20px;
                        }
                    }
                }

                .dropdown-menu {
                    @media (min-width: $xsWidth) {
                        left: auto;
                        right: 0;
                    }
                }

                &.is-active {
                    .icon {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    .dropdown-menu {
                        opacity: 1;
                        transform: translateY(0);
                        pointer-events: auto;
                    }
                }
            }

            &.app-dropdown {
                .button {
                    margin-left: 2px;
                    padding: 0 17px;
                }

                .dropdown-menu {
                    left: auto;
                    right: 0;
                }

                &.disabled {
                    .button {
                        cursor: not-allowed;
                        background-color: transparent !important;

                        span {
                            color: $grey-light !important;
                        }
                    }

                    .dropdown-menu {
                        display: none;
                    }
                }
            }

            .dropdown-menu {
                display: block;
                opacity: 0;
                transition: all .3s ease-in-out;
                pointer-events: none;
                top: calc(100% + (-4px));
                transform: translateY(-5px);
                min-width: 200px;

                .dropdown-content {
                    box-shadow: none;
                    border: $solitude 1px solid;
                    font-weight: 400 !important;

                    .dropdown-item {
                        cursor: pointer;
                        color: $buster;

                        &.logout {
                            color: $grey-light;
                        }

                        &:hover {
                            background-color: $white-bis;
                        }
                    }
                }
            }

            &.is-active {
                .dropdown-menu {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: auto;
                }
            }
        }

        .navbar-item > a.button {
            font-family: SimplonNorm, serif;
            font-size: 14px;
            font-weight: 500;
            color: $buster;
            display: flex;
            margin-top: -1px;
            padding-top: 7px;
            border: none;
            transition: all .3s ease-in-out;

            &:hover, &:focus {
                background-color: $primary;
                box-shadow: none;
                color: $white !important;

            }
        }
    }

    .sidebar {
        li {
            ul {
                border-left: none;
                transition: opacity .3s ease-in-out;
                visibility: visible;

                &.hideSubsMenu {
                    opacity: 0;
                    visibility: hidden;
                    overflow: hidden;
                    height: 0;
                    margin: 0;

                    @media (max-width: $smWidth) {
                        display: none;
                    }
                }
            }

            a {
                margin-bottom: 8px;
                font-size: 14px;
                color: $buster;
                border-radius: 6px;
                padding: 3px 10px;
                cursor: pointer;

                &:hover {
                    background-color: $solitude;
                }

                &.is-active {
                    background-color: $gramsHair;
                }

                .sidebar--item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .icon {
                        font-size: 14px;
                        margin-right: 8px;
                        vertical-align: middle;
                    }
                }

                &.disabled {
                    background-color: transparent;
                    cursor: not-allowed;

                    span {
                        color: $grey-light;

                        img {
                            filter: invert(73%) sepia(6%) saturate(10%) hue-rotate(26deg) brightness(100%) contrast(76%);
                        }

                    }
                }

                &.hidden {
                    display: none;
                }
            }
        }

        &--label {
            line-height: 0;
        }
    }

    .freshestFooter {
        padding-top: 40px;
        @media (max-width: $smWidth) {
            padding-top: 0;
        }

        hr {
            height: 3px;
        }

        .column {
            .icon {
                font-size: 20px;
                color: $primary;
            }

            a {
                font-size: 14px;
                margin: 12px;
                color: $primary;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .about {
                margin-top: 20px;
                max-width: 380px;
                margin-left: 0;

                span {
                    color: $englishManor;
                    font-size: 13px;
                }
            }
        }
    }
}
