@import '../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

.orgTable {
    color: $buster;
    font-size: 14px;

    @media (max-width: $xsWidth) {
        .hidden-xs {
            display: none;
        }
    }

    @media (max-width: $smWidth) {
        .hidden-sm {
            display: none;
        }
    }

    tbody {
        > tr {
            background-color: transparent !important;

            &:first-child {
                td {
                    font-weight: 500;
                }
            }

            td {
                padding-left: 0;
                padding-top: 12px;
                padding-bottom: 12px;
                border-color: $solitude;
            }

            &.italic {
                font-style: italic;
            }
        }
    }

    a {
        color: $primary;
    }

    .floatRight {
        float: right;
    }

    .negative {
        font-weight: bold;
        color: $danger;
    }

    .pending {
        font-weight: bold;
        color: $turmericRoot;
    }
}