@import '../../../../assets/style/colors';

$xsWidth: 480px;
$smWidth: 768px;

#subtitle {
    font-weight: 500;
    margin: 0px;
    font-size: 15px;
}

.clientTable {

    padding: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    box-shadow: none;
    border: $solitude 1px solid;
    border-radius: 6px;

    .btnsContainer {
        display: flex;
        justify-content: right;
        align-items: center;

        .button {
            width: 70px;
        }

    }

    .sorting {
        cursor: pointer !important;
    }
}

table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    color: $buster;
    table-layout: fixed;
    width: 100%;

    .icons {
        cursor: pointer;
        color: $brainstemGrey;
        height: 100%;

        &.copyIcon {
            margin-left: 5px;
        }

    }


    tbody {
        display: table;
        width: 100%;
        height: 100%;

        tr {

            td {
                height: 100%;

                .cellContainer {
                    display: flex !important;
                    height: 100% !important;
                    align-items: center !important;
                    justify-content: center;

                    .icon-wrapper {
                        padding-left: 10px;
                        padding-right: 5px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    &.clientIdContainer {
                        justify-content: space-between;
                    }

                    &.clientNameContainer {
                        justify-content: flex-start;
                    }

                    &.justifyStart {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

.noAPIClientsMessage {
    padding: 0px 20px;
    font-weight: 500 !important;
}


.footNote {

    display: flex;


    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $xsWidth) {
        flex-direction: column;
    }

    .detailsContainer {

        flex-direction: column;

    }

    .nextPageContainer,
    .previousPageContainer,
    .detailsContainer {

        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-arrow-left {
            margin-right: 5px;
        }

        .fa-arrow-right {
            margin-left: 5px;
        }

    }

}
